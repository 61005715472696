/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthLayout from '../applications/Auth';
import ErrorLayout from '../applications/Error';
import DashboardLayout from '../applications/Main/Main';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/login',
    component: AuthLayout,
    routes: [
      {
        path: '/login',
        exact: true,
        component: lazy(() => import('views/login'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/errorpage/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/errorpage/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/errorpage/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/confirm',
    component: ErrorLayout,
    routes: [
      {
        path: '/confirm/account/:id',
        exact: true,
        component: lazy(() => import('views/konfirmasi'))
      },
      {
        path: '/confirm/timeout',
        exact: true,
        component: lazy(() => import('views/konfirmasitimeout'))
      },
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    // writer: [
    //   {
    //     path: '/dashboard',
    //     exact: true,
    //     component: lazy(() => import('views/dashboard/writer'))
    //   },
    //   {
    //     path: '/blog/post',
    //     exact: true,
    //     component: lazy(() => import('views/blog/post'))
    //   },
    //   {
    //     path: '/blog/post/form',
    //     exact: true,
    //     component: lazy(() => import('views/blog/post/Tambah'))
    //   },
    //   {
    //     path: '/comment',
    //     exact: true,
    //     component: lazy(() => import('views/writer/index'))
    //   },
    // ],
    partner: [
      {
        path: '/sales/job',
        exact: true,
        component: lazy(() => import('views/job/sales/index'))
      },
      {
        path: '/partner/banksetting',
        exact: true,
        component: lazy(() => import('views/sales/banksetting'))
      },
      {
        path: '/partner/withdraw',
        exact: true,
        component: lazy(() => import('views/sales/withdraw'))
      },
      {
        path: '/partner/saldo/riwayat',
        exact: true,
        component: lazy(() => import('views/partner/riwayat'))
      },
      {
        path: '/sales/saldo/view',
        exact: true,
        component: lazy(() => import('views/sales/saldo/view'))
      },
      {
        path: '/sales/saldo/benefit',
        exact: true,
        component: lazy(() => import('views/sales/bonus'))
      },
      {
        path: '/sales/saldo',
        exact: true,
        component: lazy(() => import('views/sales/saldo'))
      },
      {
        path: '/sales/datareferal',
        exact: true,
        component: lazy(() => import('views/sales/datareferal'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/dashboard/partner'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      
      {
        path: '/outlet/daftar-outlet/:id/:tab',
        exact: true,
        component: lazy(() => import('views/outlet/Tambah'))
      },
      {
        path: '/voucher/pembuatan',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan'))
      },
      {
        path: '/voucher/pembuatan/form',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Tambah'))
      },
      {
        path: '/voucher/pembuatan/detail',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Detail'))
      },
      {
        path: '/voucher/data',
        exact: true,
        component: lazy(() => import('views/voucher/data'))
      },
      {
        path: '/voucher/pemakaian',
        exact: true,
        component: lazy(() => import('views/voucher/pemakaian'))
      },
      {
        path: '/voucher/laporan',
        exact: true,
        component: lazy(() => import('views/voucher/laporan'))
      },
      {
        path: '/voucher/request',
        exact: true,
        component: lazy(() => import('views/voucher/request'))
      },
      {
        path: '/voucher/pembuatan/form',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Tambah'))
      },
    ],
    sales: [
         // blog
      {
        path: '/blog/kategori',
        exact: true,
        component: lazy(() => import('views/blog/kategori'))
      },
      {
        path: '/blog/kategori/form',
        exact: true,
        component: lazy(() => import('views/blog/kategori/Tambah'))
      },
      {
        path: '/blog/subkategori',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori'))
      },
      {
        path: '/blog/subkategori/form',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori/Tambah'))
      },
      {
        path: '/blog/post',
        exact: true,
        component: lazy(() => import('views/blog/post'))
      },
      {
        path: '/blog/post/form',
        exact: true,
        component: lazy(() => import('views/blog/post/Tambah'))
      },
      {
        path: '/blog/review',
        exact: true,
        component: lazy(() => import('views/blog/review'))
      },
      {
        path: '/sales/job/reject',
        exact: true,
        component: lazy(() => import('views/job/tolak/index'))
      },
      {
        path: '/sales/job/approve',
        exact: true,
        component: lazy(() => import('views/job/terima/index'))
      },
      {
        path: '/sales/job',
        exact: true,
        component: lazy(() => import('views/job/sales/index'))
      },
      {
        path: '/sales/banksetting',
        exact: true,
        component: lazy(() => import('views/sales/banksetting'))
      },
      {
        path: '/sales/withdraw',
        exact: true,
        component: lazy(() => import('views/sales/withdraw'))
      },
      {
        path: '/sales/saldo/riwayat',
        exact: true,
        component: lazy(() => import('views/sales/riwayat'))
      },
      {
        path: '/sales/saldo/view',
        exact: true,
        component: lazy(() => import('views/sales/saldo/view'))
      },
      {
        path: '/sales/saldo/benefit',
        exact: true,
        component: lazy(() => import('views/sales/bonus'))
      },
      {
        path: '/sales/saldo',
        exact: true,
        component: lazy(() => import('views/sales/saldo'))
      },
      {
        path: '/sales/datareferal',
        exact: true,
        component: lazy(() => import('views/sales/datareferal'))
      },
      {
        path: '/profil',
        exact: true,
        component: lazy(() => import('views/profil'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/dashboard/sales'))
      },
      {
        path: '/progress',
        exact: true,
        component: lazy(() => import('views/sales/progress/index'))
      },
      {
        path: '/progress/kunjungan',
        exact: true,
        component: lazy(() => import('views/sales/progress/kunjungan/index'))
      },
      {
        path: '/progress/trial',
        exact: true,
        component: lazy(() => import('views/sales/progress/trial/index'))
      },
      {
        path: '/progress/followup',
        exact: true,
        component: lazy(() => import('views/sales/progress/followup/index'))
      },
      {
        path: '/progress/aktivasi',
        exact: true,
        component: lazy(() => import('views/sales/progress/aktivasi/index'))
      },
      {
        path: '/sales/progress/detail',
        exact: true,
        component: lazy(() => import('views/sales/progress/detail'))
      },
      {
        path: '/sales/kunjungan/form',
        exact: true,
        component: lazy(() => import('views/sales/kunjungan/Tambah'))
      },
    ],
    writer: [
      // ringkasan outlet
      {
        path: '/outlet/ringkasan-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/index'))
      },
      {
        path: '/outlet/ringkasan-outlet/Info',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/InfoOutlet'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      // blog
      {
        path: '/blog/kategori',
        exact: true,
        component: lazy(() => import('views/blog/kategori'))
      },
      {
        path: '/blog/kategori/form',
        exact: true,
        component: lazy(() => import('views/blog/kategori/Tambah'))
      },
      {
        path: '/blog/subkategori',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori'))
      },
      {
        path: '/blog/subkategori/form',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori/Tambah'))
      },
      {
        path: '/blog/post',
        exact: true,
        component: lazy(() => import('views/blog/post'))
      },
      {
        path: '/blog/post/form',
        exact: true,
        component: lazy(() => import('views/blog/post/Tambah'))
      },
      {
        path: '/blog/review',
        exact: true,
        component: lazy(() => import('views/blog/review'))
      },
      {
        path: '/profil',
        exact: true,
        component: lazy(() => import('views/profil'))
      },
      // support
      // {
      //   path: '/support/faq',
      //   exact: true,
      //   component: lazy(() => import('views/support/faq'))
      // },
      // {
      //   path: '/support/faq/form',
      //   exact: true,
      //   component: lazy(() => import('views/support/faq/Tambah'))
      // },
      // {
      //   path: '/support/help',
      //   exact: true,
      //   component: lazy(() => import('views/support/help'))
      // },
      // {
      //   path: '/support/help/form',
      //   exact: true,
      //   component: lazy(() => import('views/support/help/Tambah'))
      // },
      // {
      //   path: '/support/toc',
      //   exact: true,
      //   component: lazy(() => import('views/support/toc'))
      // },
      // {
      //   path: '/support/privacy',
      //   exact: true,
      //   component: lazy(() => import('views/support/pp'))
      // },
    ],
    sales_officer: [
      createRoute('support/vidio_kategori'),
       createRoute('support/vidio_kategori/Tambah'),
      createRoute('support/help_kategori'),
       createRoute('support/help_kategori/Tambah'),
      createRoute('feedback/vidio_kategori'),
      createRoute('blaztap/barang_keluar'),
      createRoute('blaztap/order_blaztap'),
      createRoute('blaztap/laporan'),
      createRoute('blaztap_repair/return'),
      createRoute('blaztap_repair/kerusakan'),
      createRoute('blaztap_repair/perlu_dikerjakan'),
      createRoute('blaztap_repair/sampah'),
      createRoute('setting'),
      createRoute('outlet/extendtrial'),
      {
        path: '/voucher/approval',
        exact: true,
        component: lazy(() => import('views/voucher/approval'))
      },
      {
        path: '/penjualan/produk/retail',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/index'))
      },
      {
        path: '/penjualan/produk/retail/form',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/Tambah'))
      },
      {
        path: '/penjualan/ringkasan/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view'))
      },
      {
        path: '/penjualan/ringkasan/view/topkota',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_kota'))
      },
      {
        path: '/penjualan/ringkasan/view/topsupplier',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_supplier'))
      },
      {
        path: '/penjualan/invoice',
        exact: true,
        component: lazy(() => import('views/penjualan/invoice'))
      },
      {
        path: '/penjualan/data_order',
        exact: true,
        component: lazy(() => import('views/penjualan/data_order'))
      },
      {
        path: '/penjualan/preorder',
        exact: true,
        component: lazy(() => import('views/penjualan/preorder'))
      },
      createRoute('penjualan/preorder/[id]'),
      {
        path: '/penjualan/topproductview/view',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview/view'))
      },
      {
        path: '/penjualan/topproductview',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview'))
      },
      {
        path: '/penjualan/onchart/view',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart/view'))
      },
      {
        path: '/penjualan/onchart',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart'))
      },
      {
        path: '/penjualan/selesai/view',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai/view'))
      },
      {
        path: '/penjualan/selesai',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai'))
      },
      {
        path: '/penjualan/inproses/view',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses/view'))
      },
      {
        path: '/penjualan/inproses',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses'))
      },
      {
        path: '/penjualan/konfirmasiorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder/view'))
      },
      {
        path: '/penjualan/konfirmasiorder',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder'))
      },
      {
        path: '/penjualan/pendingorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder/view'))
      },
      {
        path: '/penjualan/pendingorder',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder'))
      },
      {
        path: '/penjualan/ongkir',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir'))
      },
      {
        path: '/penjualan/ongkir/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir/Tambah'))
      },
      {
        path: '/banner',
        exact: true,
        component: lazy(() => import('views/banner'))
      },
      {
        path: '/banner/form',
        exact: true,
        component: lazy(() => import('views/banner/Tambah'))
      },
      {
        path: '/banner/promosi',
        exact: true,
        component: lazy(() => import('views/banner/promosi'))
      },
      {
        path: '/banner/promosi/form',
        exact: true,
        component: lazy(() => import('views/banner/promosi/Tambah'))
      },
      {
        path: '/dropbox',
        exact: true,
        component: lazy(() => import('views/dropbox'))
      },
      {
        path: '/betatester/report',    
        exact: true,
        component: lazy(() => import('views/betatester/report'))
      },
      {
        path: '/betatester/bug',    
        exact: true,
        component: lazy(() => import('views/betatester/bug'))
      },
      {
        path: '/betatester/data/view',    
        exact: true,
        component: lazy(() => import('views/betatester/data/form'))
      },
      {
        path: '/betatester/pendaftaran/view',    
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran/form'))
      },
      {
        path: '/betatester/data',    
        exact: true,
        component: lazy(() => import('views/betatester/data'))
      },
      {
        path: '/betatester/pendaftaran',
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran'))
      },
      {
        path: '/outlet/betatester',
        exact: true,
        component: lazy(() => import('views/betatester'))
      },
      {
        path: '/outlet/trialbisnis',
        exact: true,
        component: lazy(() => import('views/outlettrialbisnis'))
      },
      {
        path: '/outlet/owner',
        exact: true,
        component: lazy(() => import('views/owner'))
      },
      {
        path: '/outlet/owner/:id/detail',
        exact: true,
        component: lazy(() => import('views/owner/DetailOwner'))
      },
      {
        path:'/referensiharga',
        exact: true,
        component: lazy(() => import('views/referensiharga'))
      },
      {
        path: '/feedback/bugtest',
        exact: true,
        component: lazy(() => import('views/feedback/bug'))
      },
      {
        path: '/voucher/data/order/tambah',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/addorder'))
      },
      {
        path: '/profil',
        exact: true,
        component: lazy(() => import('views/profil'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      {
        path: '/outlet/antrian-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/antrian_outlet'))
      },
      
      {
        path: '/outlet/daftar-outlet/:id/:tab',
        exact: true,
        component: lazy(() => import('views/outlet/Tambah'))
      },
      {
        path: '/outlet/daftar-outlet/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/outlet/owner',
        exact: true,
        component: lazy(() => import('views/owner'))
      },
      {
        path: '/outlet/owner/:id/detail',
        exact: true,
        component: lazy(() => import('views/owner/DetailOwner'))
      },
      {
        path: '/outlet/profil-outlet/:id',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/outlet-upgrade',
        exact: true,
        component: lazy(() => import('views/outletupgrade'))
      },
      {
        path: '/outlet/outlet-archive/:id',
        exact: true,
        component: lazy(() => import('views/outletarchive/detailarchive'))
      },
      {
        path: '/outlet/outlet-archive',
        exact: true,
        component: lazy(() => import('views/outletarchive'))
      },
      {
        path: '/outlet/ringkasan-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/index'))
      },
      {
        path: '/outlet/ringkasan-outlet/:id/detail',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/ringkasan-outlet/Info',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/InfoOutlet'))
      },
      {
        path: '/outlet/outlet-aktif',
        exact: true,
        component: lazy(() => import('views/outlet/pertumbuhan_outlet'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/dashboard/salesofficer'))
      },
      {
        path: '/feedback/testimoni',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni'))
      },
      {
        path: '/feedback/bug/form',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Tambah'))
      },
      {
        path: '/feedback/bug/detail',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Detail'))
      },
      {
        path: '/feedback/bug/tambah',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/TambahDetail'))
      },
      {
        path: '/feedback/bug',
        exact: true,
        component: lazy(() => import('views/feedback/bug'))
      },
      {
        path: '/feedback/testimoni/form',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni/Tambah'))
      },
      {
        path: '/feedback/masukkan',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan'))
      },
      {
        path: '/feedback/masukkan/form',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan/Tambah'))
      },
      {
        path: '/support/faq',
        exact: true,
        component: lazy(() => import('views/support/faq'))
      },
      {
        path: '/support/faq/form',
        exact: true,
        component: lazy(() => import('views/support/faq/Tambah'))
      },
      {
        path: '/support/help',
        exact: true,
        component: lazy(() => import('views/support/help'))
      },
      {
        path: '/support/help/form',
        exact: true,
        component: lazy(() => import('views/support/help/Tambah'))
      },
      {
        path: '/support/toc',
        exact: true,
        component: lazy(() => import('views/support/toc'))
      },
      {
        path: '/support/privacy',
        exact: true,
        component: lazy(() => import('views/support/pp'))
      },
      {
        path: '/livechat',
        exact: true,
        component: () => <Redirect to="/livechat/none" />
      },
      {
        path: '/livechat/:room',
        exact: true,
        component: lazy(() => import('views/livechat'))
      },
      createRoute('broadcast/wa'),
      createRoute('broadcast/template'),
        {
        path: '/broadcast/template/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatTemplate'))
      },
      {
        path: '/broadcast/wa/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatBroadcast'))
      },
      createRoute('broadcast/koneksi'),
      {
        path: '/broadcast',
        exact: true,
        component: lazy(() => import('views/broadcast'))
      },
      {
        path: '/broadcast/form',
        exact: true,
        component: lazy(() => import('views/broadcast/Tambah'))
      },
      {
        path: '/autobroadcast',
        exact: true,
        component: lazy(() => import('views/autobroadcast'))
      },
      {
        path: '/autobroadcast/form',
        exact: true,
        component: lazy(() => import('views/autobroadcast/Tambah'))
      },
      createRoute('prospek'),
      createRoute('prospek/sukses'),
      createRoute('prospek/batch'),
      createRoute('prospek/ringkasan'),
      createRoute('prospek_new'),
      createRoute('prospek_new/sukses'),
      createRoute('prospek_new/batch'),
      createRoute('prospek_new/ringkasan'),
      createRoute('prospek_new/klaim'),
      createRoute('jadwaltraining'),
      createRoute('jadwaltraining/trainingvirtual'),
      createRoute('jadwaltraining/trainingonlocation'),
      createRoute('jadwaltraining/ringkasan'),
      createRoute('event'),
      {
        path: '/event/:id',
        exact: true,
        component: lazy(() => import('views/event/Tambah'))
      },
      {
        path: '/jadwaltraining/form',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/Tambah'))
      },
      {
        path: '/jadwaltraining/selesai',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/selesai'))
      },
      {
        path: '/jadwaltraining/dibatalkan',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/dibatalkan'))
      },
      {
        path: '/prospek_new/ringkasan/detail',
        exact: true,
        component: lazy(() => import('views/prospek_new/ringkasan/Detail'))
      },
      {
        path: '/daftar_order',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/index'))
      },
      {
        path: '/daftar_order/:id',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/Tambah'))
      },
      // {
      //   path: '/laporan_order',
      //   exact: true,
      //   component: lazy(() => import('views/order/laporan_order/index'))
      // },
      {
        path: '/ringkasan_order',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/index'))
      },
      {
        path: '/ringkasan_order/detail',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/info'))
      },
      // {
      //   path: '/ringkasan_order/:id/info',
      //   exact: true,
      //   component: lazy(() => import('views/promo/detail'))
      // },
      {
        path: '/repeat_order',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/index'))
      },
    ],
    cs: [
      createRoute('support/vidio_kategori'),
       createRoute('support/vidio_kategori/Tambah'),
      createRoute('support/help_kategori'),
       createRoute('support/help_kategori/Tambah'),
      createRoute('feedback/vidio_kategori'),
      createRoute('blaztap/barang_keluar'),
      createRoute('blaztap/order_blaztap'),
      createRoute('blaztap/laporan'),
      createRoute('blaztap_repair/return'),
      createRoute('blaztap_repair/kerusakan'),
      createRoute('blaztap_repair/perlu_dikerjakan'),
      createRoute('blaztap_repair/sampah'),
         // blog
         {
          path: '/blog/kategori',
          exact: true,
          component: lazy(() => import('views/blog/kategori'))
        },
        {
          path: '/blog/kategori/form',
          exact: true,
          component: lazy(() => import('views/blog/kategori/Tambah'))
        },
        {
          path: '/blog/subkategori',
          exact: true,
          component: lazy(() => import('views/blog/sub_kategori'))
        },
        {
          path: '/blog/subkategori/form',
          exact: true,
          component: lazy(() => import('views/blog/sub_kategori/Tambah'))
        },
        {
          path: '/blog/post',
          exact: true,
          component: lazy(() => import('views/blog/post'))
        },
        {
          path: '/blog/post/form',
          exact: true,
          component: lazy(() => import('views/blog/post/Tambah'))
        },
        {
          path: '/blog/review',
          exact: true,
          component: lazy(() => import('views/blog/review'))
        },
      {
        path: '/voucher/approval',
        exact: true,
        component: lazy(() => import('views/voucher/approval'))
      },
      {
        path: '/penjualan/produk/retail',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/index'))
      },
      {
        path: '/penjualan/produk/retail/form',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/Tambah'))
      },
      {
        path: '/penjualan/ringkasan/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view'))
      },
      {
        path: '/penjualan/ringkasan/view/topkota',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_kota'))
      },
      {
        path: '/penjualan/ringkasan/view/topsupplier',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_supplier'))
      },
      {
        path: '/penjualan/invoice',
        exact: true,
        component: lazy(() => import('views/penjualan/invoice'))
      },
      {
        path: '/penjualan/data_order',
        exact: true,
        component: lazy(() => import('views/penjualan/data_order'))
      },
      {
        path: '/penjualan/preorder',
        exact: true,
        component: lazy(() => import('views/penjualan/preorder'))
      },
      createRoute('penjualan/preorder/[id]'),
      {
        path: '/penjualan/topproductview/view',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview/view'))
      },
      {
        path: '/penjualan/topproductview',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview'))
      },
      {
        path: '/penjualan/onchart/view',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart/view'))
      },
      {
        path: '/penjualan/onchart',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart'))
      },
      {
        path: '/penjualan/selesai/view',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai/view'))
      },
      {
        path: '/penjualan/selesai',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai'))
      },
      {
        path: '/penjualan/inproses/view',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses/view'))
      },
      {
        path: '/penjualan/inproses',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses'))
      },
      {
        path: '/penjualan/konfirmasiorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder/view'))
      },
      {
        path: '/penjualan/konfirmasiorder',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder'))
      },
      {
        path: '/penjualan/pendingorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder/view'))
      },
      {
        path: '/penjualan/pendingorder',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder'))
      },
      {
        path: '/penjualan/ongkir',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir'))
      },
      {
        path: '/penjualan/ongkir/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir/Tambah'))
      },
      {
        path: '/banner',
        exact: true,
        component: lazy(() => import('views/banner'))
      },
      {
        path: '/banner/form',
        exact: true,
        component: lazy(() => import('views/banner/Tambah'))
      },
      {
        path: '/banner/promosi',
        exact: true,
        component: lazy(() => import('views/banner/promosi'))
      },
      {
        path: '/banner/promosi/form',
        exact: true,
        component: lazy(() => import('views/banner/promosi/Tambah'))
      },
      {
        path: '/dropbox',
        exact: true,
        component: lazy(() => import('views/dropbox'))
      },
      {
        path: '/outletaktivasi',
        exact: true,
        component: lazy(() => import('views/outletaktivasi'))
      },
      {
        path: '/outletaktivasi/view',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/view'))
      },
      {
        path: '/outletaktivasi/custom',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/index'))
      },
      {
        path: '/outletaktivasi/draf',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/draf'))
      },
      {
        path: '/betatester/report',    
        exact: true,
        component: lazy(() => import('views/betatester/report'))
      },
      {
        path: '/betatester/bug',    
        exact: true,
        component: lazy(() => import('views/betatester/bug'))
      },
      {
        path: '/betatester/data/view',    
        exact: true,
        component: lazy(() => import('views/betatester/data/form'))
      },
      {
        path: '/betatester/pendaftaran/view',    
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran/form'))
      },
      {
        path: '/betatester/data',    
        exact: true,
        component: lazy(() => import('views/betatester/data'))
      },
      {
        path: '/betatester/pendaftaran',
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran'))
      },
      {
        path: '/outlet/betatester',
        exact: true,
        component: lazy(() => import('views/betatester'))
      },
      {
        path: '/outlet/trialbisnis',
        exact: true,
        component: lazy(() => import('views/outlettrialbisnis'))
      },
      {
        path:'/referensiharga',
        exact: true,
        component: lazy(() => import('views/referensiharga'))
      },
      {
        path: '/feedback/bugtest',
        exact: true,
        component: lazy(() => import('views/feedback/bug'))
      },
      {
        path: '/voucher/data/order/tambah',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/addorder'))
      },
      {
        path: '/profil',
        exact: true,
        component: lazy(() => import('views/profil'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      {
        path: '/outlet/antrian-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/antrian_outlet'))
      },
      
      {
        path: '/outlet/daftar-outlet/:id/:tab',
        exact: true,
        component: lazy(() => import('views/outlet/Tambah'))
      },
      {
        path: '/outlet/daftar-outlet/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/outlet/owner',
        exact: true,
        component: lazy(() => import('views/owner'))
      },
      {
        path: '/outlet/owner/:id/detail',
        exact: true,
        component: lazy(() => import('views/owner/DetailOwner'))
      },
      {
        path: '/outlet/profil-outlet/:id',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/outlet-upgrade',
        exact: true,
        component: lazy(() => import('views/outletupgrade'))
      },
      {
        path: '/outlet/outlet-archive/:id',
        exact: true,
        component: lazy(() => import('views/outletarchive/detailarchive'))
      },
      {
        path: '/outlet/outlet-archive',
        exact: true,
        component: lazy(() => import('views/outletarchive'))
      },
      {
        path: '/outlet/ringkasan-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/index'))
      },
      {
        path: '/outlet/ringkasan-outlet/:id/detail',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/ringkasan-outlet/Info',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/InfoOutlet'))
      },
      {
        path: '/outlet/outlet-aktif',
        exact: true,
        component: lazy(() => import('views/outlet/pertumbuhan_outlet'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/dashboard/cs'))
      },
      {
        path: '/feedback/testimoni',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni'))
      },
      {
        path: '/feedback/bug/form',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Tambah'))
      },
      {
        path: '/feedback/bug/detail',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Detail'))
      },
      {
        path: '/feedback/bug/tambah',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/TambahDetail'))
      },
      {
        path: '/feedback/bug',
        exact: true,
        component: lazy(() => import('views/feedback/bug'))
      },
      {
        path: '/feedback/testimoni/form',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni/Tambah'))
      },
      {
        path: '/feedback/masukkan',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan'))
      },
      {
        path: '/feedback/masukkan/form',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan/Tambah'))
      },
      {
        path: '/support/faq',
        exact: true,
        component: lazy(() => import('views/support/faq'))
      },
      {
        path: '/support/faq/form',
        exact: true,
        component: lazy(() => import('views/support/faq/Tambah'))
      },
      {
        path: '/support/help',
        exact: true,
        component: lazy(() => import('views/support/help'))
      },
      {
        path: '/support/help/form',
        exact: true,
        component: lazy(() => import('views/support/help/Tambah'))
      },
      {
        path: '/support/toc',
        exact: true,
        component: lazy(() => import('views/support/toc'))
      },
      {
        path: '/support/privacy',
        exact: true,
        component: lazy(() => import('views/support/pp'))
      },
      {
        path: '/livechat',
        exact: true,
        component: () => <Redirect to="/livechat/none" />
      },
      {
        path: '/livechat/:room',
        exact: true,
        component: lazy(() => import('views/livechat'))
      },
      createRoute('broadcast/wa'),
      createRoute('broadcast/template'),
        {
        path: '/broadcast/template/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatTemplate'))
      },
      {
        path: '/broadcast/wa/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatBroadcast'))
      },
      createRoute('broadcast/koneksi'),
      {
        path: '/broadcast',
        exact: true,
        component: lazy(() => import('views/broadcast'))
      },
      {
        path: '/broadcast/form',
        exact: true,
        component: lazy(() => import('views/broadcast/Tambah'))
      },
      {
        path: '/autobroadcast',
        exact: true,
        component: lazy(() => import('views/autobroadcast'))
      },
      {
        path: '/autobroadcast/form',
        exact: true,
        component: lazy(() => import('views/autobroadcast/Tambah'))
      },
      createRoute('prospek'),
      createRoute('prospek/sukses'),
      createRoute('prospek/batch'),
      createRoute('prospek/ringkasan'),
      createRoute('prospek_new'),
      createRoute('prospek_new/sukses'),
      createRoute('prospek_new/batch'),
      createRoute('prospek_new/ringkasan'),
      createRoute('prospek_new/klaim'),
      createRoute('jadwaltraining'),
      createRoute('jadwaltraining/trainingvirtual'),
      createRoute('jadwaltraining/trainingonlocation'),
      createRoute('jadwaltraining/ringkasan'),
      createRoute('event'),
      {
        path: '/event/:id',
        exact: true,
        component: lazy(() => import('views/event/Tambah'))
      },
      {
        path: '/jadwaltraining/form',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/Tambah'))
      },
      {
        path: '/jadwaltraining/selesai',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/selesai'))
      },
      {
        path: '/jadwaltraining/dibatalkan',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/dibatalkan'))
      },
      {
        path: '/prospek_new/ringkasan/detail',
        exact: true,
        component: lazy(() => import('views/prospek_new/ringkasan/Detail'))
      },
      {
        path: '/daftar_order',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/index'))
      },
      {
        path: '/daftar_order/:id',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/Tambah'))
      },
      // {
      //   path: '/laporan_order',
      //   exact: true,
      //   component: lazy(() => import('views/order/laporan_order/index'))
      // },
      {
        path: '/ringkasan_order',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/index'))
      },
      {
        path: '/ringkasan_order/detail',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/info'))
      },
      // {
      //   path: '/ringkasan_order/:id/info',
      //   exact: true,
      //   component: lazy(() => import('views/promo/detail'))
      // },
      {
        path: '/repeat_order',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/index'))
      },
      
      createRoute('setting'),
    ],
    admin: [
      
      createRoute('prospek_new/all_prospek'),
      createRoute('blog/analisis'),
      createRoute('support/vidio_kategori'),
       createRoute('support/vidio_kategori/Tambah'),
      createRoute('support/help_kategori'),
       createRoute('support/help_kategori/Tambah'),
      createRoute('feedback/vidio_kategori'),
       {
        path: '/survey/jawaban',
        exact: true,
        component: lazy(() => import('views/survey/jawaban'))
      },
      {
        path: '/survey/report',
        exact: true,
        component: lazy(() => import('views/survey/report'))
      },
      {
        path: '/survey/analisa',
        exact: true,
        component: lazy(() => import('views/survey/analisa'))
      },
      {
        path: '/survey/view',
        exact: true,
        component: lazy(() => import('views/survey/Tambah'))
      },
      {
        path: '/survey',
        exact: true,
        component: lazy(() => import('views/survey'))
      },
      createRoute('outlet/wa_bisnis'),
      createRoute('outlet/extendtrial'),
      createRoute('blaztap/bahan_baku'),
      createRoute('blaztap/bom'),
      createRoute('blaztap/bom/Tambah'),
      createRoute('blaztap/pembelian_bahan'),
      createRoute('blaztap/manufacture'),
      createRoute('blaztap/serial_number'),
      createRoute('blaztap/stock_product'),
      createRoute('blaztap/barang_keluar'),
      createRoute('blaztap/order_blaztap'),
      createRoute('blaztap/laporan'),
      createRoute('blaztap_repair/return'),
      createRoute('blaztap_repair/kerusakan'),
      createRoute('blaztap_repair/perlu_dikerjakan'),
      createRoute('blaztap_repair/sampah'),
      {
        path: '/betatester/report',    
        exact: true,
        component: lazy(() => import('views/betatester/report'))
      },
      {
        path: '/betatester/bug',    
        exact: true,
        component: lazy(() => import('views/betatester/bug'))
      },
      {
        path: '/betatester/data/view',    
        exact: true,
        component: lazy(() => import('views/betatester/data/form'))
      },
      {
        path: '/betatester/pendaftaran/view',    
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran/form'))
      },
      {
        path: '/betatester/data',    
        exact: true,
        component: lazy(() => import('views/betatester/data'))
      },
      {
        path: '/betatester/pendaftaran',
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran'))
      },
      {
        path: '/outlet/betatester',
        exact: true,
        component: lazy(() => import('views/betatester'))
      },
      {
        path: '/outlet/trialbisnis',
        exact: true,
        component: lazy(() => import('views/outlettrialbisnis'))
      },
      {
        path:'/referensiharga',
        exact: true,
        component: lazy(() => import('views/referensiharga'))
      },
      {
        path: '/voucher/data/rsl',
        exact: true,
        component: lazy(() => import('views/voucher/datarsl'))
      },
      {
        path: '/voucher/approval',
        exact: true,
        component: lazy(() => import('views/voucher/approval'))
      },
      {
        path: '/voucher/laporan/pendapatan',
        exact: true,
        component: lazy(() => import('views/voucher/laporanpendapatan'))
      },
      {
        path: '/penjualan/produk/retail',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/index'))
      },
      {
        path: '/penjualan/produk/retail/form',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/Tambah'))
      },
      {
        path: '/outletaktivasi/custom/view',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/view'))
      },
      {
        path: '/outletaktivasi/custom',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/index'))
      },
      {
        path: '/outletaktivasi/upgrade',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/upgrade/index'))
      },
      {
        path: '/outletaktivasi/ringkasan',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/ringkasan/index'))
      },
      {
        path: '/outletaktivasi/laporan',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/laporan/index'))
      },
      {
        path: '/penjualan/ringkasan/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view'))
      },
      {
        path: '/penjualan/ringkasan/view/topkota',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_kota'))
      },
      {
        path: '/penjualan/ringkasan/view/topsupplier',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_supplier'))
      },
      {
        path: '/penjualan/ringkasan',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan'))
      },
      {
        path: '/penjualan/invoice',
        exact: true,
        component: lazy(() => import('views/penjualan/invoice'))
      },
      {
        path: '/penjualan/data_order',
        exact: true,
        component: lazy(() => import('views/penjualan/data_order'))
      },
      {
        path: '/penjualan/preorder',
        exact: true,
        component: lazy(() => import('views/penjualan/preorder'))
      },
      createRoute('penjualan/preorder/[id]'),
      {
        path: '/penjualan/topproductview/view',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview/view'))
      },
      {
        path: '/penjualan/topproductview',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview'))
      },
      {
        path: '/penjualan/onchart/view',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart/view'))
      },
      {
        path: '/penjualan/onchart',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart'))
      },
      {
        path: '/penjualan/selesai/view',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai/view'))
      },
      {
        path: '/penjualan/selesai',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai'))
      },
      {
        path: '/penjualan/inproses/view',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses/view'))
      },
      {
        path: '/penjualan/inproses',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses'))
      },
      {
        path: '/penjualan/konfirmasiorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder/view'))
      },
      {
        path: '/penjualan/konfirmasiorder',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder'))
      },
      {
        path: '/penjualan/pendingorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder/view'))
      },
      {
        path: '/penjualan/pendingorder',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder'))
      },
      {
        path: '/penjualan/ongkir',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir'))
      },
      {
        path: '/penjualan/ongkir/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir/Tambah'))
      },
      {
        path: '/isiweb/form',
        exact: true,
        component: lazy(() => import('views/website/isiwebsite/form'))
      },
      {
        path: '/isiweb',
        exact: true,
        component: lazy(() => import('views/website/isiwebsite/index'))
      },
      {
        path: '/spesifikasilangganan/form',
        exact: true,
        component: lazy(() => import('views/website/spesifikasilangganan/form'))
      },
      {
        path: '/spesifikasilangganan',
        exact: true,
        component: lazy(() => import('views/website/spesifikasilangganan/index'))
      },
      {
        path: '/seo',
        exact: true,
        component: lazy(() => import('views/seo'))
      },
      {
        path: '/outlet/laporan',
        exact: true,
        component: lazy(() => import('views/outlet/laporan/index'))
      },
      {
        path: '/outlet/bonus',
        exact: true,
        component: lazy(() => import('views/outlet/bonus/index'))
      },
      {
        path: '/outletaktivasi/publish',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/publish'))
      },
      {
        path: '/outletaktivasi/pembayaran',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/pembayaran'))
      },
      {
        path: '/outletaktivasi/draf',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/draf'))
      },
      {
        path: '/sales/job/verif/riwayat',
        exact: true,
        component: lazy(() => import('views/job/admin/verif/riwayat'))
      },
      {
        path: '/sales/job',
        exact: true,
        component: lazy(() => import('views/job/admin/index'))
      },
      createRoute('dashboard'),
      createRoute('ringkasan'),
      createRoute('profil'),
      createRoute('referal/pengaturan'),
      createRoute('referal/pengaturan/form'),
      createRoute('referal/data'),
      createRoute('referal/data/detail'),
      createRoute('referal/pemakaian'),
      createRoute('poin'),
      createRoute('poin/pengaturan'),
      createRoute('poin/riwayat'),
      createRoute('poin/outlet'),
      createRoute('poin/outlet/[id]'),
      createRoute('promo'),
      createRoute('promo/detail'),
      createRoute('promo/data'),
      createRoute('promo/data/form'),
      createRoute('qris/aktivasi'),
      createRoute('setting'),
      createRoute('confiq'),
      createRoute('confiq/form'),
      createRoute('prospek'),
      createRoute('prospek/sukses'),
      createRoute('prospek/performa'),
      createRoute('prospek/batch'),
      createRoute('prospek_new'),
      createRoute('prospek_new/sukses'),
      createRoute('prospek_new/performa_cs'),
      createRoute('prospek_new/performa_sales'),
      createRoute('prospek_new/batch'),
      createRoute('jadwaltraining'),
      createRoute('jadwaltraining/trainingvirtual'),
      createRoute('jadwaltraining/trainingonlocation'),
      createRoute('jadwaltraining/ringkasan'),
      createRoute('prospek/ringkasan'),
      createRoute('prospek_new/ringkasan'),
      createRoute('prospek_new/klaim'),
      createRoute('withdrawal'),
      createRoute('withdrawal/proses'),
      createRoute('withdrawal/sukses'),
      createRoute('withdrawal/batal'),
      {
        path: '/jadwaltraining/form',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/Tambah'))
      },
      {
        path: '/jadwaltraining/selesai',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/selesai'))
      },
      {
        path: '/jadwaltraining/dibatalkan',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/dibatalkan'))
      },
      {
        path: '/prospek_new/ringkasan/detail',
        exact: true,
        component: lazy(() => import('views/prospek_new/ringkasan/Detail'))
      },
      {
        path: '/partner/form',
        exact: true,
        component: lazy(() => import('views/partner/Tambah'))
      },
      {
        path: '/partner',
        exact: true,
        component: lazy(() => import('views/partner/index'))
      },
      {
        path: '/partner/saldo',
        exact: true,
        component: lazy(() => import('views/partner/saldo'))
      },
      {
        path: '/partner/saldo/riwayat',
        exact: true,
        component: lazy(() => import('views/partner/riwayat'))
      },
      {
        path: '/partner/saldo/view',
        exact: true,
        component: lazy(() => import('views/partner/saldo/view'))
      },
      
      {
        path: '/partner/saldo/benefit',
        exact: true,
        component: lazy(() => import('views/sales/bonus'))
      },
      {
        path: '/voucher/detail',
        exact: true,
        component: lazy(() => import('views/voucher/ringkasan/detail'))
      },
      {
        path: '/voucher/ringkasan',
        exact: true,
        component: lazy(() => import('views/voucher/ringkasan'))
      },
      {
        path: '/produk/review',
        exact: true,
        component: lazy(() => import('views/produk/review'))
      },
      {
        path: '/sales/saldo',
        exact: true,
        component: lazy(() => import('views/sales/saldo'))
      },
      {
        path: '/sales/datareferal',
        exact: true,
        component: lazy(() => import('views/sales/datareferal'))
      },
      {
        path: '/sales/progress/detail',
        exact: true,
        component: lazy(() => import('views/sales/progress/detail'))
      },
      {
        path: '/sales/kunjungan/form',
        exact: true,
        component: lazy(() => import('views/sales/kunjungan/Tambah'))
      },
      {
        path: '/sales/progress',
        exact: true,
        component: lazy(() => import('views/sales/progress'))
      },
      {
        path: '/sales/reject',
        exact: true,
        component: lazy(() => import('views/sales/ditolak'))
      },
      {
        path: '/sales/suspend',
        exact: true,
        component: lazy(() => import('views/sales/suspend'))
      },
      {
        path: '/sales/pendaftaran',
        exact: true,
        component: lazy(() => import('views/sales/pendaftaran'))
      },
      {
        path: '/banner',
        exact: true,
        component: lazy(() => import('views/banner'))
      },
      {
        path: '/banner/form',
        exact: true,
        component: lazy(() => import('views/banner/Tambah'))
      },
      {
        path: '/banner/promosi',
        exact: true,
        component: lazy(() => import('views/banner/promosi'))
      },
      {
        path: '/banner/promosi/form',
        exact: true,
        component: lazy(() => import('views/banner/promosi/Tambah'))
      },
      {
        path: '/feedback/testimoni',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni'))
      },
      {
        path: '/feedback/bug/form',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Tambah'))
      },
      {
        path: '/feedback/bug/detail',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/Detail'))
      },
      {
        path: '/feedback/bug/tambah',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt/TambahDetail'))
      },
      {
        path: '/feedback/bug',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt'))
      },
      {
        path: '/sales',
        exact: true,
        component: lazy(() => import('views/sales'))
      },
      {
        path: '/sales/view',
        exact: true,
        component: lazy(() => import('views/sales/view'))
      },
      {
        path: '/sales/form',
        exact: true,
        component: lazy(() => import('views/sales/Tambah'))
      },
      {
        path: '/user',
        exact: true,
        component: lazy(() => import('views/user'))
      },
      {
        path: '/user/form',
        exact: true,
        component: lazy(() => import('views/user/Tambah'))
      },
      {
        path: '/produk/kategori',
        exact: true,
        component: lazy(() => import('views/produk/kategori'))
      },
      {
        path: '/blog/kategori',
        exact: true,
        component: lazy(() => import('views/blog/kategori'))
      },
      {
        path: '/produk/kategori/form',
        exact: true,
        component: lazy(() => import('views/produk/kategori/Tambah'))
      },
      {
        path: '/blog/kategori/form',
        exact: true,
        component: lazy(() => import('views/blog/kategori/Tambah'))
      },
      {
        path: '/blog/subkategori',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori'))
      },
      {
        path: '/blog/subkategori/form',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori/Tambah'))
      },
      {
        path: '/blog/post',
        exact: true,
        component: lazy(() => import('views/blog/post'))
      },
      {
        path: '/blog/post/form',
        exact: true,
        component: lazy(() => import('views/blog/post/Tambah'))
      },
      {
        path: '/blog/review',
        exact: true,
        component: lazy(() => import('views/blog/review'))
      },
      {
        path: '/produk/varian',
        exact: true,
        component: lazy(() => import('views/produk/varian'))
      },
      {
        path: '/produk/varian/form',
        exact: true,
        component: lazy(() => import('views/produk/varian/Tambah'))
      },
      {
        path: '/produk/sku',
        exact: true,
        component: lazy(() => import('views/produk/sku'))
      },
      {
        path: '/produk/sku/form',
        exact: true,
        component: lazy(() => import('views/produk/sku/Tambah'))
      },
      {
        path: '/feedback/testimoni/form',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni/Tambah'))
      },
      {
        path: '/feedback/masukkan',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan'))
      },
      {
        path: '/feedback/masukkan/form',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan/Tambah'))
      },
      {
        path: '/support/faq',
        exact: true,
        component: lazy(() => import('views/support/faq'))
      },
      {
        path: '/support/faq/form',
        exact: true,
        component: lazy(() => import('views/support/faq/Tambah'))
      },
      {
        path: '/support/help',
        exact: true,
        component: lazy(() => import('views/support/help'))
      },
      {
        path: '/support/help/form',
        exact: true,
        component: lazy(() => import('views/support/help/Tambah'))
      },
      {
        path: '/support/toc',
        exact: true,
        component: lazy(() => import('views/support/toc'))
      },
      {
        path: '/support/privacy',
        exact: true,
        component: lazy(() => import('views/support/pp'))
      },
      {
        path: '/support/snkref',
        exact: true,
        component: lazy(() => import('views/support/snkref'))
      },
      {
        path: '/support/snkcustom',
        exact: true,
        component: lazy(() => import('views/support/snkcustom'))
      },
      {
        path: '/support/snkrefoutlet',
        exact: true,
        component: lazy(() => import('views/support/snkrefoutlet'))
      },
      {
        path: '/livechat',
        exact: true,
        component: () => <Redirect to="/livechat/none" />
      },
      {
        path: '/livechat/:room',
        exact: true,
        component: lazy(() => import('views/livechat'))
      },
      {
        path: '/daftar_order',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/index'))
      },
      {
        path: '/daftar_order/:id',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/Tambah'))
      },
      {
        path: '/laporan_order',
        exact: true,
        component: lazy(() => import('views/order/laporan_order/index'))
      },
      {
        path: '/ringkasan_order',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/index'))
      },
      {
        path: '/ringkasan_order/detail',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/info'))
      },
      {
        path: '/ringkasan_order/:id/info',
        exact: true,
        component: lazy(() => import('views/promo/detail'))
      },
      {
        path: '/repeat_order',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/index'))
      },
      {
        path: '/repeat_order/:id/detail',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/info'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      
      {
        path: '/outlet/daftar-outlet/:id/:tab',
        exact: true,
        component: lazy(() => import('views/outlet/Tambah'))
      },
      {
        path: '/outlet/daftar-outlet/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/outlet/owner',
        exact: true,
        component: lazy(() => import('views/owner'))
      },
      {
        path: '/outlet/owner/:id/detail',
        exact: true,
        component: lazy(() => import('views/owner/DetailOwner'))
      },
      {
        path: '/outlet/profil-outlet/:id',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/outlet-upgrade',
        exact: true,
        component: lazy(() => import('views/outletupgrade'))
      },
      {
        path: '/outlet/outlet-archive/:id',
        exact: true,
        component: lazy(() => import('views/outletarchive/detailarchive'))
      },
      {
        path: '/outlet/outlet-archive',
        exact: true,
        component: lazy(() => import('views/outletarchive'))
      },
      {
        path: '/outlet/ringkasan-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/index'))
      },
      {
        path: '/outlet/ringkasan-outlet/:id/detail',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/ringkasan-outlet/Info',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/InfoOutlet'))
      },
      {
        path: '/outlet/outlet-aktif',
        exact: true,
        component: lazy(() => import('views/outlet/pertumbuhan_outlet'))
      },
      createRoute('broadcast/wa'),
      createRoute('broadcast/template'),
        {
        path: '/broadcast/template/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatTemplate'))
      },
      {
        path: '/broadcast/wa/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatBroadcast'))
      },
      createRoute('broadcast/koneksi'),
      {
        path: '/broadcast',
        exact: true,
        component: lazy(() => import('views/broadcast'))
      },
      {
        path: '/broadcast/form',
        exact: true,
        component: lazy(() => import('views/broadcast/Tambah'))
      },
      {
        path: '/autobroadcast',
        exact: true,
        component: lazy(() => import('views/autobroadcast'))
      },
      {
        path: '/autobroadcast/form',
        exact: true,
        component: lazy(() => import('views/autobroadcast/Tambah'))
      },
      {
        path: '/images',
        exact: true,
        component: lazy(() => import('views/images'))
      },
      {
        path: '/voucher/pembuatan',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan'))
      },
      {
        path: '/voucher/request',
        exact: true,
        component: lazy(() => import('views/voucher/request'))
      },
      {
        path: '/voucher/pembuatan/form',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Tambah'))
      },
      {
        path: '/voucher/pembuatan/detail',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Detail'))
      },
      {
        path: '/voucher/data',
        exact: true,
        component: lazy(() => import('views/voucher/data'))
      },
      {
        path: '/voucher/pemakaian',
        exact: true,
        component: lazy(() => import('views/voucher/pemakaian'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ],
    routes: [
      createRoute('prospek_new/all_prospek'),
      createRoute('blog/analisis'),
      createRoute('support/vidio_kategori'),
       createRoute('support/vidio_kategori/Tambah'),
      createRoute('support/help_kategori'),
       createRoute('support/help_kategori/Tambah'),
      createRoute('feedback/vidio_kategori'),
       {
        path: '/survey/jawaban',
        exact: true,
        component: lazy(() => import('views/survey/jawaban'))
      },
      {
        path: '/survey/report',
        exact: true,
        component: lazy(() => import('views/survey/report'))
      },
      {
        path: '/survey/analisa',
        exact: true,
        component: lazy(() => import('views/survey/analisa'))
      },
      {
        path: '/survey/view',
        exact: true,
        component: lazy(() => import('views/survey/Tambah'))
      },
      {
        path: '/survey',
        exact: true,
        component: lazy(() => import('views/survey'))
      },
      createRoute('outlet/wa_bisnis'),
      createRoute('outlet/extendtrial'),
      createRoute('sales_v2'),
      createRoute('sales_v2/Tambah'),
      createRoute('sales_v2/nonaktif'),
      createRoute('sales_v2/riwayat'),
      createRoute('sales_v2/performa'),
      createRoute('blaztap/bahan_baku'),
      createRoute('blaztap/bom'),
      createRoute('blaztap/bom/Tambah'),
      createRoute('blaztap/pembelian_bahan'),
      createRoute('blaztap/manufacture'),
      createRoute('blaztap/serial_number'),
      createRoute('blaztap/stock_product'),
      createRoute('blaztap/barang_keluar'),
      createRoute('blaztap/order_blaztap'),
      createRoute('blaztap/laporan'),
      createRoute('blaztap_repair/return'),
      createRoute('blaztap_repair/kerusakan'),
      createRoute('blaztap_repair/perlu_dikerjakan'),
      createRoute('blaztap_repair/sampah'),
      {
        path: '/sales_v2/detailsales/:id',
        exact: true,
        component: lazy(() => import('views/sales_v2/detailsales'))
      },
      {
        path: '/sales_v2/detailsales/:id/:tab',
        exact: true,
        component: lazy(() => import('views/sales_v2/detailsales'))
      },
      {
        path: '/voucher/produk/form',
        exact: true,
        component: lazy(() => import('views/voucher/produk/Tambah'))
      },
      {
        path: '/voucher/produk',
        exact: true,
        component: lazy(() => import('views/voucher/produk'))
      },
      {
        path: '/support/snkmitrareseller',
        exact: true,
        component: lazy(() => import('views/support/snkmitrareseller'))
      },
      {
        path: '/mitralevel/form', 
        exact: true,
        component: lazy(() => import('views/mitralevel/Tambah'))
      },
      {
        path: '/mitralevel', 
        exact: true,
        component: lazy(() => import('views/mitralevel'))
      },
      {
        path: '/dropbox/getaccesscode',
        exact: true,
        component: lazy(() => import('views/dropbox/getaccescode'))
      },
      {
        path: '/dropbox',
        exact: true,
        component: lazy(() => import('views/dropbox'))
      },
      {
        path: '/support/snkbetainstruksi',
        exact: true,
        component: lazy(() => import('views/support/snkbetainstruksi'))
      },
      {
        path: '/support/snkbetapendaftaran',
        exact: true,
        component: lazy(() => import('views/support/snkbetapendaftaran'))
      },
      {
        path: '/betatester/report',    
        exact: true,
        component: lazy(() => import('views/betatester/report'))
      },
      {
        path: '/betatester/bug',    
        exact: true,
        component: lazy(() => import('views/betatester/bug'))
      },
      {
        path: '/betatester/data/view',    
        exact: true,
        component: lazy(() => import('views/betatester/data/form'))
      },
      {
        path: '/betatester/pendaftaran/view',    
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran/form'))
      },
      {
        path: '/betatester/data',    
        exact: true,
        component: lazy(() => import('views/betatester/data'))
      },
      {
        path: '/betatester/pendaftaran',
        exact: true,
        component: lazy(() => import('views/betatester/pendaftaran'))
      },
      {
        path: '/outlet/betatester',
        exact: true,
        component: lazy(() => import('views/betatester'))
      },
      {
        path: '/outlet/trialbisnis',
        exact: true,
        component: lazy(() => import('views/outlettrialbisnis'))
      },
      {
        path: '/support/snkfreeupgrade',
        exact: true,
        component: lazy(() => import('views/support/snkfreeupgrade'))
      },
      {
        path:'/testt',
        exact: true,
        component: lazy(() => import('views/event/test'))
      },
      {
        path:'/transaksiratarata',
        exact: true,
        component: lazy(() => import('views/transaksiratarata'))
      },
      {
        path:'/nota',
        exact: true,
        component: lazy(() => import('views/nota'))
      },

      {
        path:'/referensiharga',
        exact: true,
        component: lazy(() => import('views/referensiharga'))
      },
      {
        path:'/shortlink/ringkasan',
        exact: true,
        component: lazy(() => import('views/iklan'))
      },
      { 
        path:'/shortlink/data',
        exact: true,
        component: lazy(() => import('views/iklan/data'))
      },
      {
        path: '/shortlink',
        exact: true,
        component: lazy(() => import('views/iklan'))
      },
      {
        path: '/feedback/bug',
        exact: true,
        component: lazy(() => import('views/feedback/bug'))
      },
      {
        path: '/feedback/bugtest',
        exact: true,
        component: lazy(() => import('views/feedback/bugtestt'))
      },
      {
        path: '/feedback/vidio/form',
        exact: true,
        component: lazy(() => import('views/feedback/vidio/Tambah'))
      },
      {
        path: '/feedback/vidio',
        exact: true,
        component: lazy(() => import('views/feedback/vidio'))
      },
      {
        path: '/promoter/daftar-promoter/:id',
        exact: true,
        component: lazy(() => import('views/promoter/view'))
      },
      {
        path: '/promoter/daftar-promoter/:id/:tab',
        exact: true,
        component: lazy(() => import('views/promoter/view'))
      },
      {
        path: '/promoter/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/promoter/view',
        exact: true,
        component: lazy(() => import('views/promoter/view'))
      },
      {
        path: '/promoter',
        exact: true,
        component: lazy(() => import('views/promoter'))
      },
      
      {
        path: '/reseller/daftar-reseller/:id',
        exact: true,
        component: lazy(() => import('views/reseller/view'))
      },
      {
        path: '/reseller/daftar-reseller/:id/:tab',
        exact: true,
        component: lazy(() => import('views/reseller/view'))
      },
      {
        path: '/reseller/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/reseller/view',
        exact: true,
        component: lazy(() => import('views/reseller/view'))
      },
      {
        path: '/reseller',
        exact: true,
        component: lazy(() => import('views/reseller'))
      },
      {
        path: '/voucher/data/order/tambah',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/addorder'))
      },
      {
        path: '/order/:id',
        exact: true,
        component: lazy(() => import('views/voucher/datarsl/Tambah'))
      },
      {
        path: '/voucher/data/rsl',
        exact: true,
        component: lazy(() => import('views/voucher/datarsl'))
      },
      {
        path: '/voucher/approval',
        exact: true,
        component: lazy(() => import('views/voucher/approval'))
      },
      {
        path: '/voucher/data/rsl/tambah',
        exact: true,
        component: lazy(() => import('views/voucher/datarsl/addorder'))
      },
      {
        path: '/voucher/laporan/pendapatan',
        exact: true,
        component: lazy(() => import('views/voucher/laporanpendapatan'))
      },
      {
        path: '/partner/suspend',
        exact: true,
        component: lazy(() => import('views/partner/suspend'))
      },
      {
        path: '/partner/reject',
        exact: true,
        component: lazy(() => import('views/partner/ditolak'))
      },
      {
        path: '/partner/pendaftaran',
        exact: true,
        component: lazy(() => import('views/partner/pendaftaran'))
      },
      {
        path: '/partner/view',
        exact: true,
        component: lazy(() => import('views/partner/view'))
      },
      {
        path: '/promospecial',
        exact: true,
        component: lazy(() => import('views/website/specialpromo/index'))
      },
      {
        path: '/penjualan/produk/retail',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/index'))
      },
      {
        path: '/penjualan/produk/retail/form',
        exact: true,
        component: lazy(() => import('views/penjualan/produk/Tambah'))
      },
      {
        path: '/outletaktivasi/custom/view',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/view'))
      },
      {
        path: '/outletaktivasi/custom',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/aplikasicustom/index'))
      },
      {
        path: '/outletaktivasi/upgrade',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/upgrade/index'))
      },
      {
        path: '/outletaktivasi/ringkasan',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/ringkasan/index'))
      },
      {
        path: '/outletaktivasi/laporan',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/laporan/index'))
      },
      {
        path: '/penjualan/ringkasan/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view'))
      },
      {
        path: '/penjualan/ringkasan/view/topkota',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_kota'))
      },
      {
        path: '/penjualan/ringkasan/view/topsupplier',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan/view/top_supplier'))
      },
      {
        path: '/penjualan/ringkasan',
        exact: true,
        component: lazy(() => import('views/penjualan/ringkasan'))
      },
      {
        path: '/penjualan/invoice',
        exact: true,
        component: lazy(() => import('views/penjualan/invoice'))
      },
      {
        path: '/penjualan/data_order',
        exact: true,
        component: lazy(() => import('views/penjualan/data_order'))
      },
      {
        path: '/penjualan/preorder',
        exact: true,
        component: lazy(() => import('views/penjualan/preorder'))
      },
      createRoute('penjualan/preorder/[id]'),
      {
        path: '/penjualan/topproductview/view',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview/view'))
      },
      {
        path: '/penjualan/topproductview',
        exact: true,
        component: lazy(() => import('views/penjualan/topproductview'))
      },
      {
        path: '/penjualan/onchart/view',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart/view'))
      },
      {
        path: '/penjualan/onchart',
        exact: true,
        component: lazy(() => import('views/penjualan/onchart'))
      },
      {
        path: '/penjualan/selesai/view',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai/view'))
      },
      {
        path: '/penjualan/selesai',
        exact: true,
        component: lazy(() => import('views/penjualan/selesai'))
      },
      {
        path: '/penjualan/inproses/view',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses/view'))
      },
      {
        path: '/penjualan/inproses',
        exact: true,
        component: lazy(() => import('views/penjualan/inproses'))
      },
      {
        path: '/penjualan/konfirmasiorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder/view'))
      },
      {
        path: '/penjualan/konfirmasiorder',
        exact: true,
        component: lazy(() => import('views/penjualan/konfirmasiorder'))
      },
      {
        path: '/penjualan/pendingorder/view',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder/view'))
      },
      {
        path: '/penjualan/pendingorder',
        exact: true,
        component: lazy(() => import('views/penjualan/pendingorder'))
      },
      {
        path: '/penjualan/ongkir',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir'))
      },
      {
        path: '/penjualan/ongkir/view',
        exact: true,
        component: lazy(() => import('views/penjualan/ongkir/Tambah'))
      },
      {
        path: '/isiweb/form',
        exact: true,
        component: lazy(() => import('views/website/isiwebsite/form'))
      },
      {
        path: '/isiweb',
        exact: true,
        component: lazy(() => import('views/website/isiwebsite/index'))
      },
      {
        path: '/spesifikasilangganan/form',
        exact: true,
        component: lazy(() => import('views/website/spesifikasilangganan/form'))
      },
      {
        path: '/spesifikasilangganan',
        exact: true,
        component: lazy(() => import('views/website/spesifikasilangganan/index'))
      },
      {
        path: '/seo',
        exact: true,
        component: lazy(() => import('views/seo'))
      },
      {
        path: '/outlet/laporan',
        exact: true,
        component: lazy(() => import('views/outlet/laporan/index'))
      },
      {
        path: '/outlet/bonus',
        exact: true,
        component: lazy(() => import('views/outlet/bonus/index'))
      },
      {
        path: '/outletaktivasi/publish',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/publish'))
      },
      {
        path: '/outletaktivasi/pembayaran',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/pembayaran'))
      },
      {
        path: '/outletaktivasi/draf',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/draf'))
      },
      {
        path: '/sales/job/verif/riwayat',
        exact: true,
        component: lazy(() => import('views/job/admin/verif/riwayat'))
      },
      {
        path: '/sales/withdraw',
        exact: true,
        component: lazy(() => import('views/sales/withdraw'))
      },
      {
        path: '/partner/withdraw',
        exact: true,
        component: lazy(() => import('views/partner/withdraw'))
      },
      {
        path: '/sales/job/verif',
        exact: true,
        component: lazy(() => import('views/job/admin/verif/index'))
      },
      {
        path: '/sales/job',
        exact: true,
        component: lazy(() => import('views/job/admin/index'))
      },
      createRoute('ringkasan'),
      createRoute('setting'),
      createRoute('referal/pengaturan'),
      createRoute('referal/pengaturan/form'),
      createRoute('referal/data'),
      createRoute('referal/data/detail'),
      createRoute('referal/pemakaian'),
      createRoute('poin'),
      createRoute('poin/pengaturan'),
      createRoute('poin/riwayat'),
      createRoute('poin/outlet'),
      createRoute('poin/outlet/[id]'),
      createRoute('confiq'),
      createRoute('confiq/form'),
      createRoute('promo'),
      createRoute('promo/detail'),
      createRoute('promo/data'),
      createRoute('promo/data/form'),
      createRoute('qris/aktivasi'),
      createRoute('outletaktivasi'),
      createRoute('jadwaltraining'),
      createRoute('jadwaltraining/trainingvirtual'),
      createRoute('jadwaltraining/trainingonlocation'),
      createRoute('jadwaltraining/ringkasan'),
      createRoute('prospek'),
      createRoute('prospek/sukses'),
      createRoute('prospek/batch'),
      createRoute('prospek/ringkasan'),
      createRoute('prospek_new'),
      createRoute('prospek_new/sukses'),
      createRoute('prospek_new/batch'),
      createRoute('prospek_new/ringkasan'),
      createRoute('prospek_new/klaim'),
      createRoute('prospek_new/performa_sales'),
      createRoute('prospek_new/performa_cs'),
      createRoute('withdrawal'),
      createRoute('withdrawal/proses'),
      createRoute('withdrawal/sukses'),
      createRoute('withdrawal/batal'),
      createRoute('event'),
      {
        path: '/event/:id',
        exact: true,
        component: lazy(() => import('views/event/Tambah'))
      },
      {
        path: '/prospek/ringkasan/detail',
        exact: true,
        component: lazy(() => import('views/prospek/ringkasan/Detail'))
      },
      {
        path: '/prospek_new/ringkasan/detail',
        exact: true,
        component: lazy(() => import('views/prospek_new/ringkasan/Detail'))
      },
      {
        path: '/jadwaltraining/form',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/Tambah'))
      },
      {
        path: '/jadwaltraining/selesai',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/selesai'))
      },
      {
        path: '/jadwaltraining/dibatalkan',
        exact: true,
        component: lazy(() => import('views/jadwaltraining/dibatalkan'))
      },
      {
        path: '/partner/form',
        exact: true,
        component: lazy(() => import('views/partner/Tambah'))
      },
      {
        path: '/partner',
        exact: true,
        component: lazy(() => import('views/partner/index'))
      },
      {
        path: '/partner/saldo',
        exact: true,
        component: lazy(() => import('views/partner/saldo'))
      },
      {
        path: '/partner/saldo/riwayat',
        exact: true,
        component: lazy(() => import('views/partner/riwayat'))
      },
      {
        path: '/partner/saldo/view',
        exact: true,
        component: lazy(() => import('views/partner/saldo/view'))
      },
      {
        path: '/partner/saldo/benefit',
        exact: true,
        component: lazy(() => import('views/sales/bonus'))
      },
      {
        path: '/voucher/detail',
        exact: true,
        component: lazy(() => import('views/voucher/ringkasan/detail'))
      },
      {
        path: '/voucher/ringkasan',
        exact: true,
        component: lazy(() => import('views/voucher/ringkasan'))
      },
      {
        path: '/outletaktivasi/view',
        exact: true,
        component: lazy(() => import('views/outletaktivasi/view'))
      },
      // {
      //   path: '/feedback/bug/kanban',
      //   exact: true,
      //   component: lazy(() => import('views/feedback/bug/kanban'))
      // },
      {
        path: '/profil',
        exact: true,
        component: lazy(() => import('views/profil'))
      },
      {
        path: '/voucher/laporan',
        exact: true,
        component: lazy(() => import('views/voucher/laporan'))
      },
      {
        path: '/test',
        exact: true,
        component: lazy(() => import('views/test'))
      },
      {
        path: '/sales/saldo/riwayat',
        exact: true,
        component: lazy(() => import('views/sales/riwayat'))
      },
      {
        path: '/sales/saldo/view',
        exact: true,
        component: lazy(() => import('views/sales/saldo/view'))
      },
      {
        path: '/sales/saldo/benefit',
        exact: true,
        component: lazy(() => import('views/sales/bonus'))
      },
      {
        path: '/sales/saldo',
        exact: true,
        component: lazy(() => import('views/sales/saldo'))
      },
      {
        path: '/sales/datareferal',
        exact: true,
        component: lazy(() => import('views/sales/datareferal'))
      },
      {
        path: '/sales/progress/detail',
        exact: true,
        component: lazy(() => import('views/sales/progress/detail'))
      },
      {
        path: '/sales/kunjungan/form',
        exact: true,
        component: lazy(() => import('views/sales/kunjungan/Tambah'))
      },
      {
        path: '/sales/progress',
        exact: true,
        component: lazy(() => import('views/sales/progress'))
      },
      {
        path: '/sales/reject',
        exact: true,
        component: lazy(() => import('views/sales/ditolak'))
      },
      {
        path: '/sales/suspend',
        exact: true,
        component: lazy(() => import('views/sales/suspend'))
      },
      {
        path: '/sales/pendaftaran',
        exact: true,
        component: lazy(() => import('views/sales/pendaftaran'))
      },
      {
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('views/dashboard'))
      },
      {
        path: '/banner',
        exact: true,
        component: lazy(() => import('views/banner'))
      },
      {
        path: '/banner/form',
        exact: true,
        component: lazy(() => import('views/banner/Tambah'))
      },
      {
        path: '/banner/promosi',
        exact: true,
        component: lazy(() => import('views/banner/promosi'))
      },
      {
        path: '/banner/promosi/form',
        exact: true,
        component: lazy(() => import('views/banner/promosi/Tambah'))
      },
      {
        path: '/promo',
        exact: true,
        component: lazy(() => import('views/promo/data'))
      },
      {
        path: '/promo/form',
        exact: true,
        component: lazy(() => import('views/promo/data/form'))
      },
      {
        path: '/feedback/testimoni',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni'))
      },
      // {
      //   path: '/feedback/bug/form',
      //   exact: true,
      //   component: lazy(() => import('views/feedback/bug/Tambah'))
      // },
      // {
      //   path: '/feedback/bug/detail',
      //   exact: true,
      //   component: lazy(() => import('views/feedback/bug/Detail'))
      // },
      // {
      //   path: '/feedback/bug/tambah',
      //   exact: true,
      //   component: lazy(() => import('views/feedback/bug/TambahDetail'))
      // },
      // {
      //   path: '/feedback/bug',
      //   exact: true,
      //   component: lazy(() => import('views/feedback/bug'))
      // },
      {
        path: '/sales',
        exact: true,
        component: lazy(() => import('views/sales'))
      },
      {
        path: '/sales/view',
        exact: true,
        component: lazy(() => import('views/sales/view'))
      },
      {
        path: '/sales/form',
        exact: true,
        component: lazy(() => import('views/sales/Tambah'))
      },
      {
        path: '/user',
        exact: true,
        component: lazy(() => import('views/user'))
      },
      {
        path: '/user/form',
        exact: true,
        component: lazy(() => import('views/user/Tambah'))
      },
      {
        path: '/produk/kategori',
        exact: true,
        component: lazy(() => import('views/produk/kategori'))
      },
      {
        path: '/blog/kategori',
        exact: true,
        component: lazy(() => import('views/blog/kategori'))
      },
      {
        path: '/produk/kategori/form',
        exact: true,
        component: lazy(() => import('views/produk/kategori/Tambah'))
      },
      {
        path: '/blog/kategori/form',
        exact: true,
        component: lazy(() => import('views/blog/kategori/Tambah'))
      },
      {
        path: '/blog/subkategori',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori'))
      },
      {
        path: '/blog/subkategori/form',
        exact: true,
        component: lazy(() => import('views/blog/sub_kategori/Tambah'))
      },
      {
        path: '/blog/post',
        exact: true,
        component: lazy(() => import('views/blog/post'))
      },
      {
        path: '/blog/post/form',
        exact: true,
        component: lazy(() => import('views/blog/post/Tambah'))
      },
      {
        path: '/blog/review',
        exact: true,
        component: lazy(() => import('views/blog/review'))
      },
      {
        path: '/produk/varian',
        exact: true,
        component: lazy(() => import('views/produk/varian'))
      },
      {
        path: '/produk/review',
        exact: true,
        component: lazy(() => import('views/produk/review'))
      },
      {
        path: '/produk/varian/form',
        exact: true,
        component: lazy(() => import('views/produk/varian/Tambah'))
      },
      {
        path: '/produk/sku',
        exact: true,
        component: lazy(() => import('views/produk/sku'))
      },
      {
        path: '/produk/sku/form',
        exact: true,
        component: lazy(() => import('views/produk/sku/Tambah'))
      },
      {
        path: '/feedback/testimoni/form',
        exact: true,
        component: lazy(() => import('views/feedback/testimoni/Tambah'))
      },
      {
        path: '/feedback/masukkan',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan'))
      },
      {
        path: '/feedback/masukkan/form',
        exact: true,
        component: lazy(() => import('views/feedback/masukkan/Tambah'))
      },
      {
        path: '/support/faq',
        exact: true,
        component: lazy(() => import('views/support/faq'))
      },
      {
        path: '/support/faq/form',
        exact: true,
        component: lazy(() => import('views/support/faq/Tambah'))
      },
      {
        path: '/support/help',
        exact: true,
        component: lazy(() => import('views/support/help'))
      },
      {
        path: '/support/help/form',
        exact: true,
        component: lazy(() => import('views/support/help/Tambah'))
      },
      {
        path: '/support/toc',
        exact: true,
        component: lazy(() => import('views/support/toc'))
      },
      {
        path: '/support/privacy',
        exact: true,
        component: lazy(() => import('views/support/pp'))
      },
      {
        path: '/support/snkref',
        exact: true,
        component: lazy(() => import('views/support/snkref'))
      },
      {
        path: '/support/snkcustom',
        exact: true,
        component: lazy(() => import('views/support/snkcustom'))
      },
      
      {
        path: '/support/snkrefoutlet',
        exact: true,
        component: lazy(() => import('views/support/snkrefoutlet'))
      },
      {
        path: '/livechat',
        exact: true,
        component: () => <Redirect to="/livechat/none" />
      },
      {
        path: '/livechat/:room',
        exact: true,
        component: lazy(() => import('views/livechat'))
      },
      {
        path: '/daftar_order',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/index'))
      },
      {
        path: '/daftar_order/:id',
        exact: true,
        component: lazy(() => import('views/order/daftar_order/Tambah'))
      },
      {
        path: '/laporan_order',
        exact: true,
        component: lazy(() => import('views/order/laporan_order/index'))
      },
      {
        path: '/ringkasan_order',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/index'))
      },
      {
        path: '/ringkasan_order/detail',
        exact: true,
        component: lazy(() => import('views/order/ringkasan_order/info'))
      },
      {
        path: '/repeat_order',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/index'))
      },
      {
        path: '/repeat_order/:id/detail',
        exact: true,
        component: lazy(() => import('views/order/repeat_order/info'))
      },
      {
        path: '/ringkasan_order/:id/info',
        exact: true,
        component: lazy(() => import('views/promo/detail'))
      },
      {
        path: '/outlet/daftar-outlet',
        exact: true,
        component: lazy(() => import('views/outlet'))
      },
      {
        path: '/outlet/antrian-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/antrian_outlet'))
      },
      
      {
        path: '/outlet/daftar-outlet/:id/:tab',
        exact: true,
        component: lazy(() => import('views/outlet/Tambah'))
      },
      {
        path: '/outlet/daftar-outlet/:id/:tab/:id',
        exact: true,
        component: lazy(() => import('views/outlet/transaksi/InfoTransaksi'))
      },
      {
        path: '/outlet/owner',
        exact: true,
        component: lazy(() => import('views/owner'))
      },
      {
        path: '/outlet/owner/:id/detail',
        exact: true,
        component: lazy(() => import('views/owner/DetailOwner'))
      },
      {
        path: '/outlet/profil-outlet/:id',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/outlet-upgrade',
        exact: true,
        component: lazy(() => import('views/outletupgrade'))
      },
      {
        path: '/outlet/outlet-archive/:id',
        exact: true,
        component: lazy(() => import('views/outletarchive/detailarchive'))
      },
      {
        path: '/outlet/outlet-archive',
        exact: true,
        component: lazy(() => import('views/outletarchive'))
      },
      {
        path: '/outlet/ringkasan-outlet',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/index'))
      },
      {
        path: '/outlet/ringkasan-outlet/:id/detail',
        exact: true,
        component: lazy(() => import('views/outlet/detailOutlet'))
      },
      {
        path: '/outlet/ringkasan-outlet/Info',
        exact: true,
        component: lazy(() => import('views/outlet/ringkasan_outlet/InfoOutlet'))
      },
      {
        path: '/outlet/outlet-aktif',
        exact: true,
        component: lazy(() => import('views/outlet/pertumbuhan_outlet'))
      },
      createRoute('broadcast/wa'),
      createRoute('broadcast/template'),
        {
        path: '/broadcast/template/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatTemplate'))
      },
      {
        path: '/broadcast/wa/tambah',
        exact: true,
        component: lazy(() => import('views/broadcast/buatBroadcast'))
      },
      createRoute('broadcast/koneksi'),
      {
        path: '/broadcast',
        exact: true,
        component: lazy(() => import('views/broadcast'))
      },
      {
        path: '/broadcast/form',
        exact: true,
        component: lazy(() => import('views/broadcast/Tambah'))
      },
      {
        path: '/autobroadcast',
        exact: true,
        component: lazy(() => import('views/autobroadcast'))
      },
      {
        path: '/autobroadcast/form',
        exact: true,
        component: lazy(() => import('views/autobroadcast/Tambah'))
      },
      {
        path: '/images',
        exact: true,
        component: lazy(() => import('views/images'))
      },
      {
        path: '/voucher/pembuatan',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan'))
      },
      {
        path: '/voucher/request',
        exact: true,
        component: lazy(() => import('views/voucher/request'))
      },
      {
        path: '/voucher/pembuatan/form',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Tambah'))
      },
      {
        path: '/voucher/pembuatan/detail',
        exact: true,
        component: lazy(() => import('views/voucher/pembuatan/Detail'))
      },
      {
        path: '/voucher/data',
        exact: true,
        component: lazy(() => import('views/voucher/data'))
      },
      {
        path: '/voucher/pemakaian',
        exact: true,
        component: lazy(() => import('views/voucher/pemakaian'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

function createRoute(path) {
  const mypath = "/" + path.split('[').join(':').split(']').join('');

  return {
    path: mypath,
    exact: true,
    component: lazy(() => import('views/' + path))
  }
}

routes.propTypes = {
  toggleDark:PropTypes.bool, 
  settoggleDark:PropTypes.bool
};

export default routes;
